// Libraries
import React, { Component } from "react";

// Router
import { Switch, Route } from "react-router-dom";

// Constants
// import * as C from "../../utils/constants";

// Pages/Routes
import About from "../../pages/About/About";
import Home from "../../pages/Home/Home";
import Add from "../../pages/Add/Add";
import Edit from "../../pages/Edit/Edit";
import PageNotFound from "../../pages/PageNotFound/PageNotFound";
import BotDetected from "../../pages/BotDetected/BotDetected";
import Welcome from "../../pages/Welcome/Welcome";

// Components
import Header from "../Header/Header";
// import Footer from "../Footer/Footer";

// Material-UI imports
import { Box } from "@material-ui/core";

// Styles and Visual Assets
import "./App.scss";

// External data
import localSettings from "../../assets/localSettings.json";
import Success from "../../pages/Success/Sucess";

class App extends Component {
  state = {
    settings: localSettings,
    showHeaderFooter: false,
  };

  componentDidMount() {
    this.setState({ showHeaderFooter: true });
  }

  hideHeaderFooter = () => {
    this.setState({ showHeaderFooter: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="App">
          {this.state.showHeaderFooter && (
            <Header
              title={localSettings.header.title}
              headline={localSettings.header.headline}
            />
          )}
          <Box className="main">
            {/* Router switch embed the requested page */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/add" component={Add} />
              <Route path="/welcome">
                <Welcome hideHeaderFooter={this.hideHeaderFooter} />
              </Route>
              <Route path="/botdetected">
                <BotDetected hideHeaderFooter={this.hideHeaderFooter} />
              </Route>
              <Route exact path="/edit/:place_id" component={Edit} />
              <Route exact path="/success/:place_id" component={Success} />
              <Route path="*">
                <PageNotFound hideHeaderFooter={this.hideHeaderFooter} />
              </Route>
            </Switch>
          </Box>
        </div>
        {/* {this.state.showHeaderFooter && <Footer />} */}
      </React.Fragment>
    );
  }
}

export default App;
