import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

// Artwork
import logo from "../../assets/localscouts-logo-color.svg";
import art404 from "../../assets/404-artwork.png";

import "./PageNotFound.scss";

const PageNotFound = (props) => {
  useEffect(() => {
    props.hideHeaderFooter();
  }, []);

  return (
    <Container maxWidth="sm">
      <Box className="pageNotFound">
        <Box my={3} className="brand-home">
          <Link href="/">
            <img src={logo} className="App-logo" alt="LocalScouts.app Logo" />
            <Typography variant="h1" color="secondary">
              <span className="text-bold">LocalScouts.app</span>
            </Typography>
          </Link>
        </Box>
        <Grid container justify="center">
          <Grid item xs={8}>
            <img src={art404} alt="dog shedding a lot of fur" />
          </Grid>
          <Grid item xs={8} className="text404">
            <Typography variant="h2">Classic 404. smh</Typography>
            <Typography variant="body2">
              We lost the page in all that fur!
            </Typography>
            <Typography variant="body2">
              If you keep seeing this, please<Box component="span">&nbsp;</Box>
              <Link href="mailto:help@localscouts.app" color="secondary">
                get it touch
              </Link>
              <Box component="span">.</Box>
            </Typography>
          </Grid>
        </Grid>
        <Box className="button-block" my={3}>
          <Button variant="outlined" color="secondary" href="/">
            Take me Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PageNotFound;
