import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputBase,
  IconButton,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@material-ui/core";

// ApiCall Import
import * as ApiCall from "../../utils/apiCall";

import SearchRoundedIcon from "@material-ui/icons/SearchRounded";

import "./Filters.scss";

const Filters = (props) => {
  // Create JSX fragment of Filters component
  const [currentValue, setCurrentValue] = useState(1);
  const [cities, setCities] = useState([]);
  // const [city, setCity] = useState("");

  // Utility methods
  const getCities = () => {
    ApiCall.getCities("GET", {}, (err, res) => {
      if (res.status !== 200) {
        console.log("Unable to fetch cities");
      } else {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].city == "Santa Monica") {
            setCurrentValue(i + 1);
            break;
          }
        }
        setCities(res.data);
      }
    });
  };

  const updateFilter = (item, index) => {
    setCurrentValue(index + 1);
    props.setSearchCity(item.city);
    props.setSearchState(item.state);
  };

  // Capture enter keypress and trigger parseInput()
  const enterPressed = (e) => {
    const code = e.keyCode || e.which;
    // Capture "Enter" key press
    if (code === 13) {
      e.preventDefault();
      props.getBusinessData(0);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  const Filters = (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <div className="searchbar">
              <InputBase
                value={props.search_term}
                placeholder="Search by Name or Tag"
                inputProps={{ "aria-label": "search by name or tag" }}
                variant="outlined"
                onChange={(event) => props.setSearch(event.target.value)}
                onKeyPress={(e) => enterPressed(e)}
              />
              <Divider orientation="vertical" flexItem className="hidden-xs" />
              <FormControl>
                {/* <InputLabel id="city-select" >City</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentValue}
                  variant="outlined"
                >
                  <ListSubheader className="city-dropdown-message">
                    Only showing cities where businesses have been added
                  </ListSubheader>
                  {cities.map((item, index) => {
                    return (
                      <MenuItem
                        value={index + 1}
                        onClick={() => updateFilter(item, index)}
                      >{`${item.city}, ${item.state}`}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Tooltip title="Click to Search">
                <IconButton
                  type="submit"
                  aria-label="search"
                  onClick={() => props.getBusinessData(0)}
                >
                  <SearchRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <Box mt={1} mb={4} className="filters">
      {Filters}
    </Box>
  );
};

export default Filters;
