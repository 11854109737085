import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { load } from "recaptcha-v3";
// import axios from "axios";

// Helper methods
// import * as helpers from "../../utils/helpers.js";
import * as C from "../../utils/constants.js";

// API CALL IMPORT
import * as ApiCall from "../../utils/apiCall";

// Material UI Icons
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

// Open/Close toggle button component
import Bizstatus from "../Add/Bizstatus";
import GoogleMapsIframe from "./../../components/GoogleMapsIframe/GoogleMapsIframe";

export default function Edit(props) {
  const latlon = "";

  // Create a variable to store place name in local component state
  const [biz, setBiz] = useState("");
  const [place, setPlace] = useState("");
  const [placestatus, setPlaceStatus] = useState(1);
  const [placetype, setPlaceType] = useState("");
  const [placesource, setPlaceSource] = useState("");
  const [placeupdates, setPlaceUpdates] = useState("");
  const [tags, setTags] = useState([]);

  // Form Validation state
  const [placetypeError, setPlaceTypeError] = useState(false);
  const [placesourceError, setPlacesourceError] = useState(false);
  const [placeupdatesError, setPlaceupdatesError] = useState(false);

  useEffect(() => {
    executeRecaptcha();
  }, []);

  /**
   * Get recaptcha token
   */
  async function executeRecaptcha() {
    const recaptcha = await load(C.RECAPTCHA_SITE_KEY);
    const token = await recaptcha.execute("add_business");
    return token;
  }

  function parseTags(e, value) {
    console.log(value);
    setTags(value);
  }

  // Get business using place id
  function getBusinessByPlaceID() {
    let place_id = props.match.params.place_id;
    ApiCall.getBusinessByPlaceID("GET", { place_id }, (err, res) => {
      if (!err && res.status === 200) {
        setBiz(res.data);
        setBusinessData(res.data);
      }
    });
  }

  // Set initial business data
  function setBusinessData(biz) {
    setPlace(biz.name);
    setPlaceStatus(biz.open);
    setPlaceType(biz.business_type);
    setPlaceSource(biz.source);
    setPlaceUpdates(biz.updates);
    let bizTagsFormat1 = [];
    let bizTagsFormat2 = [];
    if (biz.tags.includes("#")) {
      bizTagsFormat1 = biz.tags.split("#");
    } else {
      bizTagsFormat2 = biz.tags.split(",");
    }
    let formattedTags = [];
    for (let index1 in C.HASHTAGS) {
      for (let index2 in bizTagsFormat1) {
        if (C.HASHTAGS[index1].title === bizTagsFormat1[index2].trim()) {
          formattedTags.push(C.HASHTAGS[index1]);
          break;
        }
      }
      for (let index2 in bizTagsFormat2) {
        if (C.HASHTAGS[index1].title === bizTagsFormat2[index2].trim()) {
          formattedTags.push(C.HASHTAGS[index1]);
          break;
        }
      }
    }
    setTags(formattedTags);
  }

  // Check captcha response and call submitForm
  async function verifyCaptchaToken() {
    let token = await executeRecaptcha();
    if (token) {
      const body = {
        token,
      };
      ApiCall.siteverify("POST", body, (err, res) => {
        if (res.data.score >= 0.5) {
          submitForm();
        }
        // If bot detected, redirect to error page
        else {
          props.history.push({
            pathname: `/botdetected`,
          });
        }
      });
    }
  }

  // Capture user input, merge with place_id, POST to api
  function submitForm() {
    let isError = false;
    if (!placetype) {
      setPlaceTypeError(true);
      isError = true;
    } else {
      setPlaceTypeError(false);
    }
    if (!placeupdates) {
      setPlaceupdatesError(true);
      isError = true;
    } else {
      setPlaceupdatesError(false);
    }
    if (!placesource) {
      setPlacesourceError(true);
      isError = true;
    } else {
      setPlacesourceError(false);
    }
    if (isError) return;
    console.log("******* Form Submitted *****");

    let tagArray = [];
    // Keep only first object index in Tags
    tags.forEach(function (tag) {
      tagArray.push(tag.title);
    });
    // Create # separated string of tags
    tagArray = tagArray.join(" #");
    tagArray = "#" + tagArray;

    const postPayload = {
      name: place,
      business_type: placetype,
      updates: placeupdates,
      source: placesource,
      latlon: latlon,
      open: placestatus,
      tags: tagArray,
      place_id: biz.place_id,
      address: biz.address,
      phone_number: biz.phone_number,
      google_url: biz.google_url,
      city: biz.city,
      state: biz.state,
      country: biz.country,
      postal_code: biz.postal_code,
    };

    console.log(">>>>>> PATCH /place >>>>>>> ");
    ApiCall.updateBusiness(
      "patch",
      postPayload,
      (err, res) => {
        if (!err) {
          props.history.push({
            pathname: `/success/${postPayload.place_id}`,
            state: {
              data: postPayload,
            },
          });
        }
      },
      {
        Accept: "application/json",
        "Content-type": "application/json",
      }
    );
  } // end submitForm

  // ON PAGE LOAD
  useEffect(() => {
    getBusinessByPlaceID();
  }, []);

  // JSX
  return (
    <Box mt={1} mb={8} className="content">
      <Paper elevation={0} variant="outlined" className="paper-padding-b">
        {/* Business Info, Category, and Tags */}
        <Box>
          <Box className="paper-padding">
            <Typography variant="h2">Edit Business</Typography>
          </Box>

          {/* GOOGLE MAPS IFRAME */}
          <Box className="paper-padding-x">
            <GoogleMapsIframe
              apiKey={C.GMAPS_EMBED_API_KEY}
              // placeName="Bestia" // hardcoded for testing purposes
              placeName={place}
            />
          </Box>
          <Box my={4} className="paper-padding-x">
            <Typography variant="body1">
              Learned something new about this business? Add it below.
            </Typography>
          </Box>

          {/* FORM BEGINS HERE */}
          <form>
            <Box mb={4} className="paper-padding-x">
              <Bizstatus
                clickHandler={setPlaceStatus}
                selected={true}
                value={placestatus}
              />
            </Box>
            <Box mb={4} className="paper-padding-x">
              <FormControl variant="outlined" fullWidth error={placetypeError}>
                <InputLabel id="city" required={true}>
                  Type of Business
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Type of Business"
                  value={placetype}
                  onChange={(e) => setPlaceType(e.target.value)}
                >
                  <MenuItem value={1}>Restaurant</MenuItem>
                  <MenuItem value={2}>Bakery / Cafe</MenuItem>
                  <MenuItem value={3}>Grocery Store</MenuItem>
                  <MenuItem value={4}>Books / Electronics Store</MenuItem>
                  <MenuItem value={5}>Doctor / Vet</MenuItem>
                </Select>
                {placetypeError && (
                  <FormHelperText>This is required</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mb={4} className="paper-padding-x">
              <TextField
                fullWidth
                value={placeupdates}
                error={placeupdatesError}
                multiline
                placeholder="Any new services or related information that you are aware of?"
                rows={4}
                required={true}
                label="COVID-19 Updates"
                variant="outlined"
                onChange={(e) => setPlaceUpdates(e.target.value)}
                helperText={placeupdatesError ? "This is required" : ""}
              />
            </Box>
            <Box mb={4} className="paper-padding-x">
              <FormControl
                variant="outlined"
                fullWidth
                error={placesourceError}
              >
                <InputLabel id="source" required={true}>
                  Source
                </InputLabel>
                <Select
                  labelId="source-select-label"
                  id="source-select"
                  label="Source"
                  value={placesource}
                  onChange={(e) => setPlaceSource(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"1"}>
                    Business Website / Social Media
                  </MenuItem>
                  <MenuItem value={"2"}>Personal Experience</MenuItem>
                  <MenuItem value={"3"}>Other</MenuItem>
                </Select>
                {placesourceError && (
                  <FormHelperText>This is required</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mb={4} className="paper-padding-x">
              <Autocomplete
                multiple
                id="tags-outlined"
                options={C.HASHTAGS}
                getOptionLabel={(option) => option.title}
                defaultValue={[C.HASHTAGS[1]]}
                value={tags}
                onChange={(event, value) => parseTags(event, value)}
                // defaultValue={[C.HASHTAGS[1]]}
                noOptionsText="That search didn't work :("
                // ChipProps={<Avatar alt={option.title} src={option.brandlogo} />}
                renderOption={(option) => (
                  <React.Fragment>
                    <div className="tags-list-item">
                      <span className="tags-logo">
                        <img alt="" src={option.brandlogo} />
                      </span>
                      <span className="tags-title">{option.title}</span>
                    </div>
                  </React.Fragment>
                )}
                renderTags={(option, getTagProps) =>
                  option.map((item, index) => {
                    return (
                      <Chip
                        className={"MuiAutocomplete-tag"}
                        avatar={
                          item.brandlogo ? (
                            <Avatar alt={item.brandlogo} src={item.brandlogo} />
                          ) : null
                        }
                        label={item.title}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Start Typing ..."
                    helperText="No custom tags, please!"
                  />
                )}
              />
            </Box>

            {/* <Box mt={2} className="paper-padding-x">
              <Typography align="right" variant="body2">Are we missing any fields? <Link href="#" target="_blank" rel="noopener">Share feedback</Link>.</Typography>
            </Box> */}

            {/* BACK / SAVE BUTTONS */}
            <Box className="paper-padding-x" mt={6}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Tooltip title="Cancel">
                  <IconButton color="primary" href="/" aria-label="Cancel">
                    <ArrowBackRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className="custom-button-padding"
                  onClick={verifyCaptchaToken.bind(this)}
                >
                  Update
                </Button>
              </Grid>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  ); // End JSX
}
