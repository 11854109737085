import React from "react";
import Linkify from "react-linkify";
// import ReactMarkdown from 'react-markdown';
// import Tag from '../Tag/Tag';

// Material UI Styles
import {
  Box,
  Button,
  Link,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";

// Material UI Icons
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

import "./BusinessCard.scss";

// Helper methods
import * as helpers from "../../utils/helpers.js";

const BusinessCard = (props) => {
  const sources = {
    "1": "Business Website / Social Media",
    "2": "Personal Experience",
    "3": "Other",
  };

  const getTags = (tags) => {
    let formattedTags = [];
    if (tags.includes("#")) {
      formattedTags = tags.split("#");
    } else {
      formattedTags = tags.split(",");
    }
    return formattedTags;
  };

  const redirectToEdit = (data) => {
    props.history.push({
      pathname: `/edit/${data.place_id}`,
      state: {
        data,
      },
    });
  };

  const searchByTag = (tag) => {
    // Check if setSearch prop is available only
    // then proceed coz businessCard component maybe used
    // in some other places as well other than home
    if (props.setSearch) {
      props.setSearch(tag);
      props.getBusinessData(0, tag);
    }
  };

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <Box className="card-behind-border" mb={4}>
      <Box className={`business__card ${props.inFocus ? "project-focus" : ""}`}>
        <Box className="card-title">
          <Typography variant="h2" color="primary">
            <Link
              href={props.biz.google_url}
              target="_blank"
              rel="noopener"
              className="biz-link"
              // title="View Business - Opens in a new tab"
            >
              <span>{props.biz.name}</span>
              <OpenInNewRoundedIcon fontSize="small" />
            </Link>
          </Typography>
          <Typography variant="body2" color="primary">
            {props.biz.address}
          </Typography>
        </Box>
        <Box className="card-details">
          <Box className="covid-updates">
            <Linkify componentDecorator={componentDecorator}>
              {props.biz.updates.split("\n").map((item, index) => {
                if (item)
                  return <Typography variant="body1">{item}</Typography>;
                else return <br />;
              })}
            </Linkify>
            <Typography
              variant="caption"
              gutterBottom
              className="biz-info-source"
            >
              Source: {sources[props.biz.source]} · Last Update:{" "}
              {helpers.getTime(props.biz.updated)}
            </Typography>
          </Box>
          <Box className="business-tags">
            <Box className="">
              {/* <div className="tech">{ tagsList }</div> */}
              <Typography variant="body1">
                <div>
                  {getTags(props.biz.tags).map((item, index) => {
                    if (item)
                      return (
                        <Button
                          component="button"
                          color="secondary"
                          onClick={() => searchByTag(item.trim())}
                          className="single-tag"
                        >
                          #<span>{item.trim()}</span>
                        </Button>
                      );
                  })}
                </div>
              </Typography>
            </Box>
            <Tooltip title="Edit Information">
              <IconButton
                aria-label="Edit Business Information"
                color="primary"
                className="edit-biz-btn"
                onClick={() => redirectToEdit(props.biz)}
              >
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessCard;
