// Adding a new component for the Open/Close toggle button. Feel free to refactor if there's a better way.

import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function ToggleButtonNotEmpty(props) {
    const [bizstatus, setBizstatus] = React.useState(1);

    const handleBizstatus = (event, newBizstatus) => {
        if (newBizstatus !== null) {
            setBizstatus(newBizstatus);
        }
        props.clickHandler(newBizstatus)
    };

    useEffect(() => {
        setBizstatus(props.value !== undefined ? props.value : 1)
    }, [props.value])

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
                <ToggleButtonGroup
                    value={bizstatus}
                    exclusive
                    onChange={handleBizstatus}
                    aria-label="Is this Business Open or Closed?"
                >
                    <ToggleButton value={1} aria-label="Open">
                        Open
                    </ToggleButton>
                    <ToggleButton value={0} aria-label="Closed">
                        Closed
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    );
}
