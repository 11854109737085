// @mihai, please refactor this part if it shouldn't be part of this file
import postmateslogo from '../assets/brandicon-postmates.png'
import grubhublogo from '../assets/brandicon-grubhub.png'
import doordashlogo from '../assets/brandicon-doordash.png'
import ubereatslogo from '../assets/brandicon-ubereats.png'
import seamlesslogo from '../assets/brandicon-seamless.png'
import caviarlogo from '../assets/brandicon-caviar.png'
import allsetlogo from '../assets/brandicon-allset.png'
import orderformelogo from '../assets/brandicon-orderforme.png'

//
// Constants and API keys
//

export const HASHTAGS = [
  {
    title: 'Takeout',
    brandlogo: ''
  },
  {
    title: 'Delivery',
    brandlogo: ''
  },
  {
    title: 'CurbsidePickup',
    brandlogo: ''
  },
  {
    title: 'OrderbyPhone',
    brandlogo: ''
  },
  {
    title: 'OrderOnline',
    brandlogo: ''
  },
  {
    title: 'NoContactDelivery',
    brandlogo: ''
  },
  {
    title: 'ModifiedOperations',
    brandlogo: ''
  },
  {
    title: 'NewServices',
    brandlogo: ''
  },
  {
    title: 'MaskRequired',
    brandlogo: ''
  },
  {
    title: 'OpenForDine-In',
    brandlogo: ''
  },
  {
    title: 'BlackOwned',
    brandlogo: ''
  },
  {
    title: 'OutdoorPatio',
    brandlogo: ''
  },
  {
    title: 'ByAppointmentOnly',
    brandlogo: ''
  },
  {
    title: 'Postmates',
    brandlogo: postmateslogo,
  },
  {
    title: 'Grubhub',
    brandlogo: grubhublogo,
  },
  {
    title: 'DoorDash',
    brandlogo: doordashlogo
  },
  {
    title: 'UberEats',
    brandlogo: ubereatslogo
  },
  {
    title: 'Seamless',
    brandlogo: seamlesslogo
  },
  {
    title: 'Caviar',
    brandlogo: caviarlogo
  },
  {
    title: 'Allset',
    brandlogo: allsetlogo
  },
  {
    title: 'OrderForMe',
    brandlogo: orderformelogo
  },
]

export const GMAPS_EMBED_API_KEY = 'AIzaSyAhP5owN3_NkieBiSzLv6T5bRo0jM0yTkc'
export const GMAPS_PLACES_API_KEY = 'AIzaSyAb-s0pOs5JZ8FDt1MT7Ox-n-EXhgsoH3A'
export const API_BASE_URL = 'https://us-central1-localscouts.cloudfunctions.net/app'
export const RECAPTCHA_SITE_KEY = '6LdTWqQZAAAAAI96tYn-VtpNz64NbAoAbATQl0X-'
