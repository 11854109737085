//
// Helper methods and Ajax requests
//


// Libraries
import axios from 'axios'
import * as C from '../utils/constants.js'

/**
 * Convert CSV data into JSON object with header columns
 * as object keys
 * @param {string} csvData
 * @return {object}
 */
export function mapCsvToJson(csvData) {
  let lines = csvData.split('\n')
  let tempHeaders = lines.shift().split(',')
  let headers = []

  // Clean up stray double quotes
  tempHeaders.forEach(header => {
    headers.push(header.replace(/['"]+/g, ''))
  })

  // console.log(headers)

  let jsonData = []
  lines.forEach(line => {
    let row = {};
    let currentLine = line.split(',')

    for (let j = 0; j < headers.length; j++) {
      row[headers[j]] = currentLine[j];
    }
    jsonData.push(row);
  })
  return jsonData
}

/**
 * Convert Hex color codes to RGBA
 * @param  {string} hex
 * @param  {float} opacity
 * @return {string} result
 */
export function hexToRgba(hex, opacity) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  return result;
}

/**
 * Grab place name and lat/lon from a full Google Maps URL
 * @param {*} url
 */
export function parseGoogleMapsUrl(url) {
  let parser = document.createElement('a')
  parser.href = url

  let returnPayload = {
    name: "",
    lat: "",
    lon: "",
  }

  // Given this url:
  // https://www.google.com/maps/place/The+Counter/@34.0196098,-118.4548722,15z/data=!4m5!3m4!1s0x0:0x697ebf90170566b7!8m2!3d34.0196098!4d-118.4548722
  //
  // We will return ["maps", "place", "The+Counter", "@34.0196098,-118.4548722,15z"
  //
  const urlParts = parser.pathname.split('/').splice(1, 4)

  // If url is bad, this is the time to bail out
  if (urlParts.length < 4) {
    return returnPayload
  }

  // Grab name and lat/lon coordinates
  let placeName = decodeURIComponent(urlParts[2])
  let latLonChunk = urlParts[3].replace('@', '').split(',')

  // Once again, another chance to bailout without throwing errors
  if (parseInt(Math.abs(latLonChunk[0])) > 0 && parseInt(Math.abs(latLonChunk[1])) > 0) {
    // Return name of place and lat,lon as a concatenated string
    returnPayload = {
      name: placeName.trim().replace(/\+/g, ' ').toUpperCase(),
      latlon: `${latLonChunk[0]},${latLonChunk[1]}`
    }
  } else {
    return returnPayload
  }
  console.log(returnPayload)
  return returnPayload
}

/**
 * Find a specific place's Google Places API id
 * @param {*} place
 * @param {*} latlon (optional)
 * @return {Promise}
 */
export async function findPlaceId(place, latlon) {
  console.log(">>>> helpers.js/ queryPlace()  >>> ")
  let place_id = '';
  const baseUrl = `${C.API_BASE_URL}/searchnearby`

  const placeName = place.trim()
    .replace(/ /g, "+")
    .toUpperCase()

  const requestUrl = (latlon !== '')
    ? encodeURI(`${baseUrl}/${placeName}/${latlon}`)
    : encodeURI(`${baseUrl}/${placeName}`)

  // Call our Google Places API wrapper and return a Promise
  try {
    const response = await axios.get(
      (requestUrl), {
      crossDomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
    console.log(">>----------------------->>>>>>>>>>>>>>>>>")
    console.log(response.data)
    console.log(">>----------------------->>>>>>>>>>>>>>>>>")

    // Three things can happen here:
    // - Exact Match
    // - Potential Match
    // - No match at all
    //
    // If found, append place_id to `postPayload`
    if (response.data?.results?.length > 0) {
      console.log(` Found an exact match `)
      place_id = response.data.results[0]?.place_id
    } else if ((response.data?.candidates !== undefined) && response.data.candidates.length > 0) {
      console.log(` Found a potential match `)
      place_id = response.data.candidates[0].place_id
    } else {
      console.log(`No Matches Found :'()`)
    }

    return place_id
  } catch (error) {
    console.log(error)
  }
}

/**
 * Return details on a given place_id
 * @param {*} place_id
 * @return {Promise}
 */
export async function getPlaceDetails(place_id) {
  const apiUrl = `${C.API_BASE_URL}/search/${place_id.trim()}`

  try {
    const response = await axios.get(
      (apiUrl), {
      crossDomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}


const pad = (data) => {
  if (parseInt(data) < 10) return '0' + data
  return data
}


/**
 * Return time in the format 2 days ago,etc
 * @param {*} timeStamp
 */
export function getTime(timeStamp) {
  let date1 = new Date(timeStamp * 1000)
  let date2 = new Date();
  let diffTime = date2.getTime() - date1.getTime();
  let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  var diffSeconds = (date2.getTime() - date1.getTime()) / 1000;
  let unit = 'days'
  if (diffDays >= 365) {
    diffDays = parseInt(diffDays / 365)
    unit = diffDays === 1 ? 'year' : 'years'
    return diffDays + ' ' + unit + ' ago'
  }
  else if (diffDays >= 7) {
    diffDays = parseInt(diffDays / 7)
    unit = diffDays === 1 ? 'week' : 'weeks'
    return diffDays + ' ' + unit + ' ago'
  }
  else if (diffDays > 0) {
    unit = diffDays === 1 ? 'day' : 'days'
    return diffDays + ' ' + unit + ' ago'
  }
  else if (diffSeconds > 3600) {
    diffSeconds = parseInt(diffSeconds / 3600)
    unit = diffSeconds < 2 ? 'hour' : 'hours'
    return diffSeconds + ' ' + unit + ' ago'
  }
  else if (diffSeconds > 60) {
    diffSeconds = parseInt(diffSeconds / 60)
    unit = diffSeconds < 2 ? 'minute' : 'minutes'
    return diffSeconds + ' ' + unit + ' ago'
  }
  else {
    unit = parseInt(diffSeconds) <= 1 ? 'second' : 'seconds'
    return parseInt(diffSeconds) + ' ' + unit + ' ago'
  }
}

/**
 * Return time in the formatted format
 * @param {*} timeStamp
 */
export function formatTimeStamp(timeStamp) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let dateTime = new Date(timeStamp)
  let YYYY = dateTime.getFullYear()
  let MM = pad(dateTime.getMonth() + 1)
  let DD = pad(dateTime.getDate())
  let hh = dateTime.getHours()
  let mm = pad(dateTime.getMinutes())
  let ss = pad(dateTime.getSeconds())
  let ampm = hh >= 12 ? 'pm' : 'am'
  hh = pad(hh % 12)
  hh = hh === '00' ? '12' : hh
  return { YYYY, MM, MMM: months[dateTime.getMonth()], DD, hh, mm, ss, ampm, }
}
