import Request from './request';

export function getBusinessByPlaceID(method, body, cb, headers) {
    Request(`/place/${body.place_id}`, method, body, cb, headers);
}

export function addBusiness(method, body, cb, headers) {
    Request(`/place`, method, body, cb, headers);
}

export function updateBusiness(method, body, cb, headers) {
    Request(`/place/${body.place_id}`, method, body, cb, headers);
}

export function getCities(method, body, cb) {
    Request(`/cities${body.pagination ? body.pagination : ''}`, method, body, cb);
}

export function siteverify(method, body, cb) {
    Request(`/siteverify`, method, body, cb);
}
