// Libraries
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Fab, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab/";
import AddLocationRoundedIcon from '@material-ui/icons/AddLocationRounded';

// Helpers
import * as C from "../../utils/constants.js";

// Components
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import BusinessCard from "../../components/BusinessCard/BusinessCard";
import Filters from "../../components/Filters/Filters";
import NoResults from "../../components/NoResults/NoResults";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
// import Introblock from "../../components/Introblock/Introblock";

export default function Home(props) {
  // Setting initial state
  const initialState = {
    businesses: [],
    // bizList: '',
    loading: true,
  };

  // Getter and setter for businesses state
  const [search, setSearch] = useState("");
  const [city, setSearchCity] = useState("Santa Monica");
  const [state, setSearchState] = useState("California");
  const [businesses, setBusinesses] = useState(initialState);
  const [totalPages, setTotalPages] = useState(0);
  const [page_number, setPageNumber] = useState(0);
  const [cityStateChanged, setCityStateChanged] = useState(false);
  const page_length = 20;

  // const [cityStateChanged, setCityStateChanged] = useState(false)

  const fetchPayload = {
    filters: {
      city,
      state,
      // postal_code: 90401,
      // country: "USA"
    },
    pagination: {
      page_number,
      page_length,
    },
  };

  // Utility functions
  const getBusinessData = async (page_number, search_param = "") => {
    setBusinesses({ ...businesses, loading: true });
    // console.log("[ getBusinessData ]");
    // Get business data
    if (search_param) {
      fetchPayload["search"] = search_param;
    } else if (search) {
      fetchPayload["search"] = search;
    }

    axios
      .post(`${C.API_BASE_URL}/places`, {
        ...fetchPayload,
        pagination: { ...fetchPayload.pagination, page_number },
      })
      .then((res) => {
        console.log(">>> POST /places ");
        console.log(res.data.data);

        const bizData = res.data.data;
        const pagination = res.data.pagination;

        // Build item cards and update local state
        if (bizData) {
          let bizList = bizData.map((biz, index) => {
            return (
              <ErrorBoundary key={`eb${index}`}>
                <BusinessCard
                  {...props}
                  key={index}
                  biz={biz}
                  setSearch={setSearch}
                  getBusinessData={getBusinessData}
                />
              </ErrorBoundary>
            );
          });
          setTotalPages(Math.ceil(pagination.totalRows / page_length));
          setBusinesses(bizList);
          window.scrollTo(0, 0);
        } else {
          setTotalPages(0);
          setBusinesses([]);
        }
        setPageNumber(page_number);
      });
  };

  // Using useEffect to retrieve data from an API (similar to componentDidMount in a class)
  useEffect(() => {
    getBusinessData(0);
  }, []);

  // Return a table with some data from the API.
  return businesses.loading ? (
    <React.Fragment>
      <Box className="content">
        <Filters
          search_term={search}
          setSearch={setSearch}
          setSearchCity={setSearchCity}
          setSearchState={setSearchState}
          getBusinessData={getBusinessData}
        />
        {/* Animated loading block based on Mui-Skeleton */}
        <LoadingCard />
      </Box>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Box className="content">
        {/* Adding filters block only for home page */}
        <Filters
          search_term={search}
          setSearch={setSearch}
          setSearchCity={setSearchCity}
          setSearchState={setSearchState}
          getBusinessData={getBusinessData}
        />
        {/* Block to load businesses */}
        <div className="listings">{businesses}</div>
        {!businesses.length && <NoResults />}
        {totalPages > 1 && (
          <Box mb={4}>
            <Grid container justify="center" mb={2}>
              <Pagination
                page={page_number + 1}
                count={totalPages}
                variant="outlined"
                onChange={(event, page) => {
                  getBusinessData(page - 1);
                }}
              />
            </Grid>
          </Box>
        )}
        <Fab className="visible-md" color="secondary" aria-label="add a business" href="/add">
          <AddLocationRoundedIcon />
        </Fab>
      </Box>
    </React.Fragment>
  );
}
