import React, { useEffect } from "react";
import { Box, Container, Link, Paper, Typography } from "@material-ui/core";

// Artwork
import botart from "../../assets/bot-artwork.png";

// Styles
import "./BotDetected.scss";

const BotDetected = (props) => {
  useEffect(() => {
    props.hideHeaderFooter();
  }, []);

  return (
    <Container maxWidth="sm">
      <Box mt={6} className="botDetected">
        <Paper elevation={0} variant="outlined">
          <Box className="img-top-banner">
            <img src={botart} className="img-fluid" alt="Map Artwork" />
          </Box>
          <Box className="paper-padding">
            <Typography variant="h1">Bot Alert!</Typography>
            <Box mt={2}>
              <Typography variant="body2">
                Looks like a bot tried to add a business, and no one wants that!
                <br />
                If this was an error on our part, then please{" "}
                <Link href="/add" color="secondary">
                  try again
                </Link>
                .
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography variant="body1">
                <Link className="brand-link" href="/" color="secondary">
                  LocalScouts.app
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default BotDetected;
