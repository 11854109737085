import React from "react";
import { Box, Link, Paper, Typography, Container } from "@material-ui/core";

// Artwork for aesthetics :)
import aboutart from "../../assets/about-artwork.svg";

const About = () => (
    <Box mt={1} mb={6} className="content">
      <Paper elevation={0} variant="outlined">
        <Box className="img-top-banner">
          <img src={aboutart} className="img-fluid" alt="Map Artwork" />
        </Box>
        <Box className="paper-padding">
          <Typography variant="h2">
            Scout and support local businesses during COVID-19
          </Typography>
          <Box mt={2}>
            <Typography variant="body2">
              <span className="logotype">
                Local<span>Scouts</span>.app
              </span>{" "}
              is a crowd-sourced app that helps people share and discover latest
              updates about businesses around them.{" "}
              <Link href="/add">Add a business</Link> to help others find and
              support their local spots.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              Many businesses have been impacted by the COVID-19 situation. What
              has been really endearing is to see how some restaurants are
              adapting and offering different services including Takeout and
              Delivery. But not everyone is aware of these new offerings. We
              wanted to build an app that offers a way for people to share such
              information and potentially help others find and support their
              local businesses during these tough times.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              If you’d like to volunteer or collaborate with us, then please
              reach out to us at{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="mailto:collaborate@localscouts.app"
              >
                collaborate@localscouts.app
              </Link>
              .
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              Have any comments, feedback or questions for us? You can reach us
              at{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="mailto:sayhi@localscouts.app"
              >
                sayhi@localscouts.app
              </Link>
              .
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              Stay safe{" "}
              <span role="img" aria-label="heart">
                ❤️
              </span>
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              - The{" "}
              <span className="logotype">
                Local<span>Scouts</span>.app
              </span>{" "}
              team
            </Typography>
          </Box>
        </Box>
      </Paper>
      <br />
      <Paper elevation={0} variant="outlined">
        <Box className="paper-padding">
          <Typography variant="h2">Privacy Policy</Typography>
          <Box mt={2}>
            <Typography variant="body2">
              We use Google's reCAPTCHA service whenever someone adds or updates
              a business. This helps keep our application free from spam and
              abuse. You can learn more about Google's{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="https://policies.google.com/privacy?hl=en"
              >
                Privacy
              </Link>{" "}
              and{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="https://policies.google.com/terms?hl=en"
              >
                Terms
              </Link>
              .
            </Typography>
            <br />
            <Typography variant="body2">
              <span className="logotype">
                Local<span>Scouts</span>.app
              </span>{" "}
              does not collect any personal information from our users.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
);

export default About;
