import React from "react";
import { Box, Link, Paper, Typography } from "@material-ui/core";

// import "./NoResults.scss";

const NoResults = (props) => {
  return (
    <Paper className="paper-padding" elevation={0} variant="outlined">
      <Box>
        <Typography variant="h2" gutterBottom>
          No results found.
        </Typography>
        <Typography variant="body2">
          Looks like we couldn't find any businesses that match your search <span role="img" aria-label="sad face">😢</span>
        </Typography>
        <Typography variant="body2">
          You can go ahead and <Link href="/add" color="secondary">add it</Link>!
        </Typography>
      </Box>
    </Paper>
  );
};

export default NoResults;
