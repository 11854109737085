import React from 'react'
import Iframe from 'react-iframe'

import './GoogleMapsIframe.scss'

const GoogleMapsIframe = ( props ) => {
  const placeName = encodeURI(
    props.placeName.trim()
    .replace(/ /g,"+")
    .replace(/&/g,"and")
  )

  const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${placeName}&key=${props.apiKey}`

  return placeName !== ''
    ? (<Iframe url={embedUrl} width="100%"height="380px"
        id="googleMapsEmbed"
        className="map-iframe"
        display="initial"
        position="relative" styles={{border: "0"}}/>)
    : ''
}

export default GoogleMapsIframe
