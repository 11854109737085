import React, {
  useState,
  useEffect,
  //useEffect
} from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
// import axios from "axios";
import { load } from "recaptcha-v3";

// Helper methods
import * as helpers from "../../utils/helpers.js";
import * as C from "../../utils/constants.js";

// API CALL IMPORT
import * as ApiCall from "../../utils/apiCall";

// Material UI Icons
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";

// Artwork for aesthetics :)
import addart from "../../assets/add-artwork.svg";

// Open/Close toggle button component
import Bizstatus from "./Bizstatus";
import GoogleMapsIframe from "./../../components/GoogleMapsIframe/GoogleMapsIframe";

// Reveal rest of the form onClick of Search button
function toggleForm() {
  var formReveal = document.getElementById("add-form-disclosure");
  formReveal.classList.add("reveal-form", "transition");
}

function hideForm() {
  var formReveal = document.getElementById("add-form-disclosure");
  formReveal.classList.remove("reveal-form", "transition");
}

export default function Add(props) {
  // Create a variable to store place name in local component state
  const [place, setPlace] = useState("");
  const [latlon, setLatLon] = useState("");
  const [placestatus, setPlaceStatus] = useState(1);
  const [placetype, setPlaceType] = useState("");
  const [placesource, setPlaceSource] = useState("");
  const [placeupdates, setPlaceUpdates] = useState("");
  const [tags, setTags] = useState([C.HASHTAGS]);

  // Form Validation state
  const [placeNameError, setPlaceNameError] = useState(false);
  const [placeNameErrorMessage, setPlaceNameErrorMessage] = useState("");
  const [placetypeError, setPlaceTypeError] = useState(false);
  const [placesourceError, setPlacesourceError] = useState(false);
  const [placeupdatesError, setPlaceupdatesError] = useState(false);

  let searchButton = "";

  /**
   *  Runs after every DOM re-render that touches the place name
   */
  // useEffect(() => {
  //   //
  // }, [place, latlon])

  useEffect(() => {
    executeRecaptcha();
  }, []);

  /**
   * Get recaptcha token
   */
  async function executeRecaptcha() {
    const recaptcha = await load(C.RECAPTCHA_SITE_KEY);
    const token = await recaptcha.execute("add_business");
    return token;
  }

  /**
   * Determine if Google Maps URL or plain text input (place name)
   * @param {*} textInput
   */
  function parseInput(textInput) {
    const gMapPrefix = "https://www.google.com/maps/place/";
    setPlaceNameError(false);
    setPlaceNameErrorMessage("");

    // Parse input, looking for either a google maps url or a place name
    if (textInput.includes(gMapPrefix)) {
      const place = helpers.parseGoogleMapsUrl(textInput);
      setPlace(place.name);
      setLatLon(place.latlon);
    } else {
      // if (textInput.trim() !== '') {
      setPlace(textInput);
      setLatLon("");
      if (!textInput) {
        hideForm();
      }
      // }
    }
  }

  // Capture enter keypress and trigger parseInput()
  function enterPressed(e) {
    const code = e.keyCode || e.which;
    // Capture "Enter" key press
    if (code === 13) {
      e.preventDefault();
      parseInput(e.target.value);
      searchButton.click();
    }
  }

  function parseTags(e, value) {
    console.log(value);
    setTags(value);
  }

  // Check captcha response and call submitForm
  async function verifyCaptchaToken() {
    let token = await executeRecaptcha();
    if (token) {
      const body = {
        token,
      };
      ApiCall.siteverify("POST", body, (err, res) => {
        if (res.data.score >= 0.5) {
          submitForm();
        }
        // If bot detected, redirect to error page
        else {
          props.history.push({
            pathname: `/botdetected`,
          });
        }
      });
    }
  }

  // Capture user input, merge with place_id, POST to api
  function submitForm() {
    // Clear all error messages first
    setPlaceNameError(false);
    setPlaceNameErrorMessage("");
    let isError = false;
    if (!placetype) {
      setPlaceTypeError(true);
      isError = true;
    } else {
      setPlaceTypeError(false);
    }
    if (!placeupdates) {
      setPlaceupdatesError(true);
      isError = true;
    } else {
      setPlaceupdatesError(false);
    }
    if (!placesource) {
      setPlacesourceError(true);
      isError = true;
    } else {
      setPlacesourceError(false);
    }
    if (isError) return;
    console.log("******* Form Submitted *****");

    let tagArray = [];
    // Keep only first object index in Tags
    tags.forEach(function (tag) {
      tagArray.push(tag.title);
    });
    // Create # separated string of tags
    tagArray = tagArray.join(" #");
    tagArray = "#" + tagArray;

    const postPayload = {
      // name: place,
      business_type: placetype,
      updates: placeupdates,
      source: placesource,
      latlon: latlon,
      open: placestatus,
      tags: tagArray,
    };

    // Get place name + id, merge data with form inputs
    helpers
      .findPlaceId(place, latlon)
      .then((placeId) => {
        console.log(`findPlaceId returned: [${placeId}]`);
        // Get place details using the place id
        helpers
          .getPlaceDetails(placeId)
          // POST to middleware API
          .then((placeInfo) => {
            // Check if placeinfo is found or else show error
            if (!placeInfo) {
              setPlaceNameError(true);
              setPlaceNameErrorMessage("Unable to find business location");
              window.scrollTo(0, 0);
              return;
            }
            // Append the rest of the required info
            console.log(
              `getPlaceDetails returned: [${JSON.stringify(placeInfo)}]`
            );
            placeInfo = placeInfo.results;
            postPayload.place_id = placeInfo.place_id;
            postPayload.address = placeInfo.formatted_address;
            postPayload.name = placeInfo.name;
            postPayload.phone_number = placeInfo.formatted_phone_number;
            postPayload.google_url = placeInfo.url;
            postPayload.city = placeInfo.city;
            postPayload.state = placeInfo.state;
            postPayload.country = placeInfo.country;
            postPayload.postal_code = placeInfo.postal_code;

            console.log(">>>>>> PUT /place >>>>>>> ");
            ApiCall.addBusiness(
              "put",
              postPayload,
              (err, res) => {
                if (err) {
                  // If duplicate entry error, show error
                  if (
                    Array.isArray(res.error.message) &&
                    res.error.message.includes("ER_DUP_ENTRY")
                  ) {
                    setPlaceNameError(true);
                    setPlaceNameErrorMessage("Business already added");
                  }
                  // TODO: handle other error types
                  else {
                    setPlaceNameError(true);
                    setPlaceNameErrorMessage(
                      "Something went wrong, please try again later."
                    );
                  }

                  // Always scroll page back up
                  window.scrollTo(0, 0);
                } else {
                  // If successfully added, redirect to success page
                  props.history.push({
                    pathname: `/success/${postPayload.place_id}`,
                    state: {
                      data: postPayload,
                    },
                  });
                }
              },
              {
                Accept: "application/json",
                "Content-type": "application/json",
              }
            );
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  } // end submitForm

  // JSX
  return (
    <Box mt={1} mb={4} className="content">
      <Paper elevation={0} variant="outlined" className="paper-padding-b">
        <Box>
          {/* <Box className="img-top-banner">
            <img src={addart} className="img-fluid" alt="Map Artwork" />
          </Box> */}
          <Box className="paper-padding">
            <Typography variant="h2">Add a Business</Typography>
            <br />
            <Typography variant="body1">
              Help others by sharing updates about a local business.
            </Typography>
          </Box>
          {/* MAP INPUT FIELD */}
          <Box className="paper-padding-x">
            <FormControl
              id="business-search"
              variant="outlined"
              fullWidth
              component="form"
              error={placeNameError}
            >
              <TextField
                id="business-name"
                label="Business Name"
                variant="outlined"
                placeholder="Eg. Andrew's Cheese Shop"
                onChange={(e) => parseInput(e.target.value)}
                onKeyPress={(e) => enterPressed(e)}
                error={placeNameError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={place ? false : true}
                        aria-label="search"
                        color="primary"
                        onClick={toggleForm.bind(this)}
                        id="business-search"
                        ref={(input) => {
                          searchButton = input;
                        }}
                        edge="end"
                      >
                        <SearchRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {!placeNameError ? (
                <FormHelperText>
                  Type in the name and hit Search. Alternatively, you can also
                  add a link from Google Maps.
                </FormHelperText>
              ) : (
                <FormHelperText>{placeNameErrorMessage}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>

        {/* Business Info, Category, and Tags */}
        <Box id="add-form-disclosure">
          {/* GOOGLE MAPS IFRAME */}
          <Box className="paper-padding-x" mt={4} mb={2}>
            <GoogleMapsIframe
              apiKey={C.GMAPS_EMBED_API_KEY}
              placeName={place}
            />
          </Box>
          <Box className="paper-padding-x">
            <Alert severity="info" className="form-info-alert">
              If you don't see a preview, then try adding the full URL from
              Google Maps.
            </Alert>
          </Box>
          {/* FORM BEGINS HERE */}
          <form>
            <Box my={3} className="paper-padding-x">
              <Typography variant="body2">
                Please add any accurate and relevant updates below.
              </Typography>
            </Box>
            <Box mb={4} className="paper-padding-x">
              <Bizstatus clickHandler={setPlaceStatus} selected={true} />
            </Box>
            <Box mb={4} className="paper-padding-x">
              <FormControl variant="outlined" fullWidth error={placetypeError}>
                <InputLabel id="city" required={true}>
                  Type of Business
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Type of Business"
                  value={placetype}
                  onChange={(e) => setPlaceType(e.target.value)}
                >
                  <MenuItem value={1}>Restaurant</MenuItem>
                  <MenuItem value={2}>Bakery / Cafe</MenuItem>
                  <MenuItem value={3}>Grocery Store</MenuItem>
                  <MenuItem value={4}>Books / Electronics Store</MenuItem>
                  <MenuItem value={5}>Doctor / Vet</MenuItem>
                </Select>
                {placetypeError && (
                  <FormHelperText>This is required</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mb={4} className="paper-padding-x">
              <TextField
                fullWidth
                error={placeupdatesError}
                multiline
                placeholder="Any new services or related information that you are aware of?"
                rows={4}
                required={true}
                label="COVID-19 Updates"
                variant="outlined"
                onBlur={(e) => setPlaceUpdates(e.target.value)}
                helperText={placeupdatesError ? "This is required" : ""}
              />
            </Box>
            <Box mb={4} className="paper-padding-x">
              <FormControl
                variant="outlined"
                fullWidth
                error={placesourceError}
              >
                <InputLabel id="source" required={true}>
                  Source
                </InputLabel>
                <Select
                  labelId="source-select-label"
                  id="source-select"
                  label="Source"
                  value={placesource}
                  onChange={(e) => setPlaceSource(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"1"}>
                    Business Website / Social Media
                  </MenuItem>
                  <MenuItem value={"2"}>Personal Experience</MenuItem>
                  <MenuItem value={"3"}>Other</MenuItem>
                </Select>
                {placesourceError && (
                  <FormHelperText>This is required</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mb={4} className="paper-padding-x">
              <Autocomplete
                multiple
                debug
                id="tags-outlined"
                options={C.HASHTAGS}
                getOptionLabel={(option) => option.title}
                defaultValue={[C.HASHTAGS[8]]}
                onChange={(event, value) => parseTags(event, value)}
                noOptionsText="That search didn't work :("
                // ChipProps={<Avatar alt={option.title} src={option.brandlogo} />}
                renderOption={(option) => (
                  <React.Fragment>
                    <div className="tags-list-item">
                      {/* if brandlogo is not empty then display images */}
                      {option.brandlogo ? (
                        <span className="tags-logo">
                          <img
                            alt={option.title + " " + "logo"}
                            src={option.brandlogo}
                          />
                        </span>
                      ) : null}
                      <span className="tags-title">{option.title}</span>
                    </div>
                  </React.Fragment>
                )}
                // Need to figure out this bit so that the rendered tags also include Logos where available
                renderTags={(option, getTagProps) =>
                  option.map((item, index) => (
                    <Chip
                      className={"MuiAutocomplete-tag"}
                      avatar={
                        item.brandlogo ? (
                          <Avatar
                            alt={item.title + "logo"}
                            src={item.brandlogo}
                          />
                        ) : null
                      }
                      label={item.title}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Start Typing ..."
                    helperText="No custom tags, please!"
                  />
                )}
              />
            </Box>

            {/* <ReCaptcha
                            sitekey={C.RECAPTCHA_SITE_KEY}
                            action='action_name'
                            verifyCallback={(recaptchaToken) => { alert(recaptchaToken, "<= your recaptcha token") }}
                        /> */}

            {/* <Box mt={2} className="paper-padding-x">
                            <Typography align="right" variant="body2">Are we missing any fields? <Link href="#" target="_blank" rel="noopener">Share feedback</Link>.</Typography>
                        </Box> */}

            {/* BACK / SAVE BUTTONS */}
            <Box className="paper-padding-x" mt={6}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Tooltip title="Back">
                  <IconButton color="primary" href="/" aria-label="Back">
                    <ArrowBackRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className="custom-button-padding"
                  // onClick={submitForm.bind(this)}
                  onClick={verifyCaptchaToken.bind(this)}
                >
                  Save
                </Button>
              </Grid>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  ); // End JSX
}
