// Define our custom theme
import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  breakpoints: {
    values: {
      sm: 700,
    },
  },

  palette: {
    primary: {
      main: "#3455DB",
    },
    secondary: {
      main: "#242038",
    },
  },

  shape: {
    borderRadius: "12px",
  },

  text: {
    primary: "#242038",
  },

  typography: {
    fontFamily: "Montserrat,sans-serif",
    htmlFontSize: 14,
    fontWeightBold: 600,

    h1: {
      fontSize: 22,
      fontWeight: 400,
    },

    h2: {
      fontSize: 21,
      fontWeight: 700,
    },

    body1: {
      fontFamily: "Montserrat,sans-serif",
      fontSize: 16,
    },

    body2: {
      fontFamily: "Montserrat,sans-serif",
      fontSize: 14,
      lineHeight: 1.6,
    },

    button: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
    },

    caption: {
      fontSize: 12,
    },
  },
});
