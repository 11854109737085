import React from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";

import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import logo from "../../assets/logo-new-circle.png";

import "./Header.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={100} />;
});

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Create JSX fragment for main header
  const headerDiv = (
    <React.Fragment>
      <Box className="intro-block-left">
        <Box className="intro-top">
          <Link href="/" className="brand-home" aria-label="Home">
            <img src={logo} className="app-logo" alt="LocalScouts.app Logo" />
          </Link>
          <Box className="visible-md">
            <Tooltip title="Help">
              <IconButton
                onClick={handleClickOpen}
                aria-label="help"
                color="secondary"
              >
                <HelpOutlineRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="hidden-md">
            <Typography variant="h1" color="secondary">
              LocalScouts.app
            </Typography>
            <Typography variant="body2">
              A crowd-sourced app to help you share and discover latest COVID-19
              related updates about businesses around you.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              href="/add"
              disableElevation
            >
              Add a Business
            </Button>
          </Box>
        </Box>
        <Box className="intro-bottom hidden-md">
          <ul className="navbar-links">
            <li>
              <Typography variant="body2">
                <Link href="/about" color="secondary">
                  About
                </Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Link href="mailto:sayhi@localscouts.app" color="secondary">
                  Feedback
                </Link>
              </Typography>
            </li>
          </ul>
          <Typography variant="body2">#SupportLocalBusinesses</Typography>
        </Box>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        class="ls-fs-dialog"
      >
        <AppBar elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="secondary"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseRoundedIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box className="intro-top">
          <img src={logo} className="app-logo" alt="LocalScouts.app Logo" />
          <Typography variant="h1" color="secondary">
            LocalScouts.app
          </Typography>
          <Typography variant="body2">
            A crowd-sourced app to help you share and discover latest COVID-19
            related updates about businesses around you.
          </Typography>
          <ul className="navbar-links">
            <li>
              <Typography variant="body2">
                <Link href="/about" color="secondary">
                  About
                </Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Link href="mailto:sayhi@localscouts.app" color="secondary">
                  Feedback
                </Link>
              </Typography>
            </li>
          </ul>
          <Box className="bottom-aligned-text">
            <Typography variant="body2">
              #SupportLocalBusinesses
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );

  return <div className="header">{headerDiv}</div>;
};

export default Header;
