import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab/";

const LoadingCard = () => {
  return (
    <Box className="card-behind-border" mb={3}>
      <Box className="business__card loader">
        <Box className="loader-top" mb={3}>
          <Skeleton width="70%" height={40} animation="wave" />
          <Skeleton width="50%" height={20} animation="wave" />
        </Box>
        <Box className="loader-mid" mb={3}>
          <Skeleton width="90%" height={30} animation="wave" />
          <Skeleton width="100%" height={30} animation="wave" />
          <Skeleton width="70%" height={20} animation="wave" />
        </Box>
        <Box className="loader-bottom">
          <Box className="">
            <Skeleton width="15%" height={30} animation="wave" />
            <Skeleton width="20%" height={30} animation="wave" />
          </Box>
          <Skeleton variant="circle" width={36} height={36} animation="pulse" />
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingCard;
