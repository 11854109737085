import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";

// Artwork
import logo from "../../assets/localscouts-logo-color.svg";
import welcomeart from "../../assets/welcome-art.svg";

// Styles
import "./Welcome.scss";

const Welcome = (props) => {
  useEffect(() => {
    props.hideHeaderFooter();
  }, []);

  document.body.className = 'welcome-only';

  return (
    <React.Fragment>
      <Container maxWidth="sm" className="welcome">
        <Box mt={6}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item sm={8}>
              <Box className="brand-home" mb={4}>
                <img
                  src={logo}
                  className="App-logo"
                  alt="LocalScouts.app Logo"
                />
              </Box>
              <Box mb={4}>
                <Typography variant="h1">
                  Let’s help our favorite businesses thrive!
                </Typography>
              </Box>
              <Typography variant="body1">
                Things you can do:
                <ul>
                  <li>Add a local business</li>
                  <li>Discover a new business in your community</li>
                  <li>Find ways to support businesses you love</li>
                </ul>
              </Typography>
              <Typography variant="body2">
                ... and all while staying safe at home.
              </Typography>
              <Box mt={5}>
                <Button
                  href="/businesses"
                  variant="contained"
                  color="primary"
                >
                  Welcome to LocalScouts!
                </Button>
              </Box>
            </Grid>
            <Grid item sm={4} className="hidden-xs">
              <img src={welcomeart} className="img-fluid" alt="Map Artwork" />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Welcome;
