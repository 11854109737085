import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Link, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import BusinessCard from "../../components/BusinessCard/BusinessCard";
import "./Success.scss";

// API CALL IMPORT
import * as ApiCall from "../../utils/apiCall";

const Success = (props) => {
  const [biz, setBiz] = useState(null);

  // Get business using place id
  const getBusinessByPlaceID = () => {
    let place_id = props.match.params.place_id;
    ApiCall.getBusinessByPlaceID("GET", { place_id }, (err, res) => {
      if (!err && res.status === 200) {
        setBiz(res.data);
      }
    });
  };

  // Mimic componentDidMount
  useEffect(() => {
    getBusinessByPlaceID();
  }, []);

  return (
    <React.Fragment>
      <Box className="content">
        <Box mt={2} mb={2}>
          <Alert
            variant="outlined"
            severity="success"
            iconMapping={{
              success: <CheckCircleRoundedIcon fontSize="inherit" />,
            }}
          >
            <b>Noice</b>! We tip our hats to you.
          </Alert>
        </Box>
        {biz && <BusinessCard {...props} key={0} biz={biz} />}
        {/* If they'd like to add businesses in bulk */}
        <Grid container className="success-caption">
          <Grid item sm={12}>
            <Box>
              <Typography variant="body2">
                You can now<Box component="span">&nbsp;</Box>
                <Link href="/add" color="secondary">
                  add another business
                </Link>
                <Box component="span">, or go back to </Box>
                <Link href="/" color="secondary">
                  browsing
                </Link>
                <Box component="span">.</Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="caption">
              If you’d like to add businesses in bulk, then please send us a
              note at<Box component="span">&nbsp;</Box>
              <Link href="mailto:sayhi@localscouts.app" color="secondary">
                sayhi@localscouts.app
              </Link>
              <Box component="span">.</Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Success;
