// IMPORTS
import Axios from 'axios';
import * as C from './constants'

// CONSTANTS
const API_URL = C.API_BASE_URL

export default function request(url, method, fields = {}, cb, headers = {}, useApi = false) {

    Axios.interceptors.request.use(request => {
        return request
    })

    Axios.interceptors.response.use(response => {
        return response
    })

    var finalUrl = ''
    if (useApi) {
        finalUrl = url;
    }
    else {
        finalUrl = API_URL + url;
    }

    Axios({ method, url: finalUrl, data: fields, headers: headers })
        .then(function (response) {
            return cb(null, response.data);
        })
        .catch(function (error) {
            console.log(error)
            if (error.response) {
                return cb(error.response.status, error.response.data);
            } else if (error.request) {
                return cb(600, 'Connection error');
            } else {
                return cb(600, 'Connection error');
            }
        });

}
